var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$apollo.loading)?_c('Loading'):_c('div',{staticClass:"background-2 px-4 px-sm-0 users-edit_card"},[_c('v-card-actions',{staticClass:"py-2 px-0 px-sm-4 btn-actions pa-2 justify-end flex-wrap",staticStyle:{"gap":"16px 8px"}},[(!_vm.desktopApp)?_c('v-btn',{attrs:{"text":"","icon":"","color":"error","disabled":(!_vm.isAdmin && !_vm.environmentUser.canRemoveUsers) ||
        (!_vm.isAdmin && _vm.environmentUser._id === _vm.user._id) ||
        _vm.$apollo.loading ||
        _vm.saving,"title":_vm.$t('enviro_edit.deleteUser')},on:{"click":_vm.deleteItem}},[_c('v-icon',[_vm._v("delete")])],1):_vm._e(),_c('v-btn',{attrs:{"text":"","icon":"","title":_vm.$t('enviro_edit.undo'),"disabled":!_vm.dirty || _vm.saving},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("undo")])],1),_c('v-flex'),_c('v-btn',{staticClass:"px-2",staticStyle:{"color":"white"},attrs:{"height":"32","depressed":"","tile":"","color":"primary","dark":!_vm.dirty ? false : true,"disabled":!_vm.dirty,"loading":_vm.$apollo.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('enviro_edit.saveBtn'))+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v("save")])],1)],1),_c('v-divider'),_c('v-card',{attrs:{"color":"transparent mx-auto","max-width":"588px","elevation":"0","tile":""}},[_c('v-card-text',{attrs:{"id":"users-edit__card-text"}},[_c('div',{staticClass:"background title-form mb-4 pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('enviro_edit.general'))+" ")]),_c('Fields',{attrs:{"id":"users_edit__fields","schema":{
          email: {
            type: 'email',
            label: _vm.$t('enviro_edit.schema.email.label'),
            fieldOptions: {
              readonly: true,
              clearable: false
            },
            newDesign: true
          },
          roles: {
            type: 'roleSelect',
            label: _vm.$t('enviro_edit.schema.roles.label'),
            fieldOptions: {
              disabled:
                _vm.desktopApp || (!_vm.isAdmin && !_vm.environmentUser.canManageRoles),
              multi: true,
              noCreate: !_vm.isAdmin,
              enabledItems: !_vm.isAdmin && _vm.environmentUser.managedRoles
            },
            optional: true,
            newDesign: true
          }
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-btn',{staticClass:"ml-4 mb-5",attrs:{"small":"","color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(_vm._s(_vm.$t('enviro_edit.cloneBtn')))]),(_vm.isAdmin || _vm.environmentUser.canEditProfiles)?[(_vm.environment.serializedProfileSchema)?_c('h2',{staticClass:"headline mx-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('enviro_edit.profile'))+" ")]):_vm._e(),(_vm.environment.serializedProfileSchema)?_c('Fields',{attrs:{"schema":_vm.environment.serializedProfileSchema,"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user.profile),callback:function ($$v) {_vm.$set(_vm.user, "profile", $$v)},expression:"user.profile"}}):_vm._e()]:_vm._e(),(_vm.isAdmin && !_vm.desktopApp)?[_c('div',{staticClass:"background title-form mb-4 pa-2 pl-4"},[_vm._v(" "+_vm._s(_vm.$t('enviro_edit.adcPermision'))+" ")]),_c('Fields',{attrs:{"id":"configUser","schema":{
            canViewAllMessages: {
              label: _vm.$t('enviro_edit.schema.canView.label'),
              type: 'boolean',
              optional: true,
              newDesign: true,
              fieldOptions: {
                inset: true
              }
            },
            canAddUsers: {
              label: _vm.$t('enviro_edit.schema.canAddUsers.label'),
              type: 'boolean',
              optional: true,
              newDesign: true,
              fieldOptions: {
                inset: true
              }
            },
            canRemoveUsers: {
              label: _vm.$t('enviro_edit.schema.canRemoveUsers.label'),
              type: 'boolean',
              optional: true,
              newDesign: true,
              fieldOptions: {
                inset: true
              }
            },
            canEditProfiles: {
              label: _vm.$t('enviro_edit.schema.canEditProfiles.label'),
              type: 'boolean',
              optional: true,
              newDesign: true,
              fieldOptions: {
                inset: true
              }
            },
            canManageRoles: {
              label: _vm.$t('enviro_edit.schema.canManageRoles.label'),
              type: 'boolean',
              optional: true,
              newDesign: true,
              fieldOptions: {
                inset: true
              }
            }
          },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-slide-y-transition',[(_vm.user.canManageRoles)?_c('Fields',{attrs:{"id":"users-edit__fields-admin-roles","schema":{
              managedRoles: {
                label: _vm.$t('enviro_edit.schema.managedRoles.label'),
                description: _vm.$t(
                  'enviro_edit.schema.managedRoles.description'
                ),
                type: 'roleSelect',
                fieldOptions: {
                  multi: true
                },
                optional: true,
                newDesign: true
              }
            },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}):_vm._e()],1)]:_vm._e()],2)],1),_c('v-dialog',{attrs:{"width":"400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pa-3"},[_c('div',{staticClass:"outline"},[_vm._v(" "+_vm._s(_vm.$t('enviro_edit.dialog.cloneRole.title'))+" ")]),_c('small',[_vm._v(_vm._s(_vm.$t('enviro_edit.dialog.cloneRole.description')))]),_c('Field',{staticClass:"mb-3",attrs:{"id":"user_clone_roles","schema":{
          label: _vm.$t('enviro_edit.schema.cloneRoles.label'),
          type: 'userSelect',
          returnRoles: true
        },"disabled":_vm.$apollo.loading || _vm.saving,"environment-variables":{ environmentId: _vm.environmentId },"validationErrors":{}},model:{value:(_vm.rolesCloned),callback:function ($$v) {_vm.rolesCloned=$$v},expression:"rolesCloned"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getRoles}},[_vm._v(_vm._s(_vm.$t('enviro_edit.cloneBtn')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }